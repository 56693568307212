import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const userToken = sessionStorage.getItem("authToken")
        const auth = userToken ? `Bearer ${userToken}` : ""
        const authReq = request.clone({
            headers: request.headers.set('Authorization', auth)
        });

        return next.handle(authReq);
    }
}
