<p-sidebar
    styleClass="p-sidebar-md"
    position="right"
    [(visible)]="isOpen"
    [dismissible]="false"
    (onHide)="onCloseEditor()"
>
    <h3>{{ isEdit ? "Edit" : "Create" }} store</h3>

    <div class="sidebar-field-list">
        <form [formGroup]="storeEditorForm" (ngSubmit)="onSubmit()">
            <div class="flex flex-column mb-5">
                <label for="storeName" class="mb-2">Store Name</label>
                <input pInputText id="storeName" formControlName="storeName" />
                @if (storeEditorForm.controls.storeName.hasError('required')) {
                <small class="p-error">Please enter store name</small>
                }
            </div>
            <div class="flex flex-column mb-5">
                <label for="storeType" class="mb-2">Store Type</label>
                <p-dropdown
                    id="storeType"
                    [options]="storeTypes"
                    optionLabel="typeName"
                    optionValue="_id"
                    placeholder="Select a store type"
                    styleClass="w-full w-20rem"
                    showClear="true"
                    formControlName="storeType"
                />
                @if (storeEditorForm.controls.storeType.hasError('required')) {
                <small class="p-error">Please select store type</small>
                }
            </div>
            <div class="flex flex-column mb-5">
                <label for="contactNo" class="mb-2">Contact No</label>
                <input
                    id="contact-no"
                    formControlName="contactNo"
                    type="tel"
                    placeholder="Contact number"
                    pInputText
                    style="padding: 1rem"
                />
                @if (storeEditorForm.controls.contactNo.hasError('required')) {
                <small class="p-error">Please enter contact number</small>
                } @if (storeEditorForm.controls.contactNo.hasError('pattern')) {
                <small class="p-error"
                    >Contact number must have only 10 numbers</small
                >
                } @if (storeEditorForm.controls.contactNo.hasError('maxlength'))
                {
                <small class="p-error"
                    >Contact number must not exceed 10 numbers</small
                >
                }
            </div>
            <div class="flex flex-column mb-5">
                <label for="description" class="mb-2">Store description</label>
                <textarea
                    pInputText
                    formControlName="description"
                    id="description"
                ></textarea>
                @if (storeEditorForm.controls.description.hasError('required'))
                {
                <small class="p-error">Please enter store description</small>
                }
            </div>
            <div class="flex flex-column mb-5">
                <label for="address" class="mb-2">Address</label>
                <textarea
                    pInputText
                    formControlName="address"
                    id="address"
                ></textarea>
            </div>
            <div class="flex flex-column mb-5">
                <label for="image" class="mb-2">Store Image</label>
                <div
                    class="flex flex-row justify-content-between border-1 border-round border-300 p-2"
                >
                    <p-fileUpload
                        id="image"
                        mode="basic"
                        name="demo[]"
                        accept="image/*"
                        [maxFileSize]="maxFileUploadSize"
                        (onSelect)="onSelect($event)"
                    >
                    </p-fileUpload>
                    <img
                        *ngIf="selectedImage"
                        class="logo shadow-3"
                        alt="Card"
                        [src]="selectedImage"
                    />
                </div>
            </div>

            <p-messages
                key="store-editor-errors"
                [value]="errorMessages | async"
            ></p-messages>
            <button
                pButton
                pRipple
                [disabled]="!storeEditorForm.valid || isLoading"
                [label]="
                    isLoading ? 'Loading...' : isEdit ? 'Update' : 'Create'
                "
                class="p-3 text-xl"
            ></button>
        </form>
    </div>
</p-sidebar>
