import {Injectable} from '@angular/core';
import {MessageService} from "primeng/api";

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor(private messageService: MessageService) {
    }

    showSuccess(summary: string, detail?: string) {
        this.messageService.add({severity: 'success', summary, detail, key: 'globalToast'});
    }

    showInfo(summary: string, detail?: string) {
        this.messageService.add({severity: 'info', summary, detail, key: 'globalToast'});
    }

    showWarn(summary: string, detail?: string) {
        this.messageService.add({severity: 'warn', summary, detail, key: 'globalToast'});
    }

    showError(summary: string, detail?: string) {
        this.messageService.add({severity: 'error', summary, detail, key: 'globalToast', life: 5000});
    }

    showContrast(summary: string, detail?: string) {
        this.messageService.add({severity: 'contrast', summary, detail, key: 'globalToast'});
    }

    showSecondary(summary: string, detail?: string) {
        this.messageService.add({severity: 'secondary', summary, detail, key: 'globalToast'});
    }
}
