import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
} from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import { AppBaseService } from '../../services/app-base.service';
import { UserTypes } from 'src/app/types/user/user-types';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements AfterViewInit {
    @Input() userStoreId?: string;

    model: Subject<any[]> = new Subject();

    constructor(
        public layoutService: LayoutService,
        private appBaseService: AppBaseService,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngAfterViewInit(): void {
        this.initMenuItems();
    }

    private getMenuItems(): any[] {
        const authUser = this.appBaseService.getAuthUser();
        const menuItems = [
            {
                label: 'Dashboard',
                icon: 'pi pi-fw pi-home',
                routerLink: ['/dashboard'],
                canAccess: [UserTypes.ADMIN, UserTypes.SUPER_ADMIN],
            },
            {
                label: 'Users',
                icon: 'pi pi-fw pi-user',
                routerLink: ['users'],
                canAccess: [UserTypes.ADMIN, UserTypes.SUPER_ADMIN],
            },
            {
                label: 'Categories',
                icon: 'pi pi-fw pi-th-large',
                routerLink: ['/category'],
                canAccess: [UserTypes.ADMIN, UserTypes.SUPER_ADMIN],
            },
            {
                label: 'Stores',
                icon: 'pi pi-fw pi-home',
                routerLink: ['/stores'],
                canAccess: [UserTypes.ADMIN, UserTypes.SUPER_ADMIN],
            },
            {
                label: 'Stores',
                icon: 'pi pi-fw pi-home',
                routerLink: [`/stores/my-store`],
                canAccess: [UserTypes.STORE_HOLDER],
            },
            {
                label: 'Store Types',
                icon: 'pi pi-fw pi-sliders-v',
                routerLink: [`/stores/store-types`],
                canAccess: [UserTypes.SUPER_ADMIN],
            },
            {
                label: 'Activity Log',
                icon: 'pi pi-fw pi-list',
                routerLink: ['/dashboard/activity-log'],
                canAccess: [UserTypes.ADMIN, UserTypes.SUPER_ADMIN],
            },
        ];
        return menuItems.filter((item) => {
            const canAccess: any[] = item.canAccess;
            return (
                canAccess?.includes(authUser.userType) ||
                canAccess?.includes('*')
            );
        });
    }

    private initMenuItems(): void {
        this.model.next([
            {
                items: this.getMenuItems(),
            },
        ]);
        this.changeDetectorRef.detectChanges();
    }
}
