export enum UserTypes {
    STORE_HOLDER = 'STORE_HOLDER',
    ADMIN = 'ADMIN',
    SUPER_ADMIN = 'SUPER_ADMIN',
}

export const UserTypesDropDownOptions = [
    {code: UserTypes.ADMIN, name: "Admin"},
    {code: UserTypes.SUPER_ADMIN, name: "Super Admin"},
    {code: UserTypes.STORE_HOLDER, name: "Store Holder"},
]

export interface ResetPasswordRequest {
  otpCode: number;
  email: string;
  password: string;
}

export interface UserLoginData {
  userName: string;
  password: string;
}

export interface UserLoginResponse {
  token: string;
}

export interface User {
  _id?: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  contactNo: string;
  userType: UserTypes;
  activeState?: boolean;
  createdAt?: string;
  updatedAt?: string;
}
export interface AddNewPasswordRequest {
  oldPassword: string;
  newPassword: string;
}

export type GetUserResponse = { users: User[], count: number }
