import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import type { ApiResponse } from 'src/app/types/api/api-response';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private httpClient: HttpClient
  ) { }

  post(apiRoute: string, postData: any, params?: HttpParams): Observable<ApiResponse> {
    return this.httpClient.post<ApiResponse>(apiRoute, postData, {params});
  }

  patch(apiRoute: string, postData: any): Observable<ApiResponse> {
    return this.httpClient.patch<ApiResponse>(apiRoute, postData);
  }

  get(apiRoute: string, params?: HttpParams): Observable<ApiResponse> {
    return this.httpClient.get<ApiResponse>(apiRoute, {params});
  }

  delete(apiRoute: string, params?: HttpParams): Observable<ApiResponse> {
    return this.httpClient.delete<ApiResponse>(apiRoute, {params});
  }
}
