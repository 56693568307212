import { Injectable } from '@angular/core';
import { Observable, Subject, map, switchMap, tap } from 'rxjs';
import { apiRoutes } from 'src/app/core/api-routes';
import { ApiService } from 'src/app/core/services/api.service';
import { AppBaseService } from 'src/app/core/services/app-base.service';
import type { ApiResponse } from 'src/app/types/api/api-response';
import type { GetStoreTypesApiResponse, StoreEditorData, StoreType } from 'src/app/types/store/store';

@Injectable({
  providedIn: 'root'
})
export class StoreEditorService {

  private openStoreEditorSubject: Subject<StoreEditorData> = new Subject();
  $openStoreEditor: Observable<StoreEditorData> = this.openStoreEditorSubject;

  getStoreTypes: Subject<StoreType[]> = new Subject();
  $getStoreTypes: Observable<StoreType[]> = this.getStoreTypes;

  constructor(
    private apiService: ApiService,
    private appBaseService: AppBaseService,
  ) { }

  createUserStore(formData: FormData): Observable<ApiResponse> {
    return this.apiService.post(apiRoutes.storeCreate, formData).pipe(
      switchMap((response) => 
        this.appBaseService.setAuthUserStore().pipe(
          map(() => response)
        )
      )
    );
  }

  updateUserStore(formData: FormData, storeId: string): Observable<ApiResponse> {
    return this.apiService.patch(apiRoutes.storeUpdate(storeId), formData).pipe(
      switchMap(() => this.getStoreById(storeId).pipe(
        map((response) => {
          this.appBaseService.setSelectedUserStore(response.data);
          return response;
        })
      ))
    );
  }

  getAllStoreTypes(): Observable<GetStoreTypesApiResponse> {
    const path = `${apiRoutes.storeTypesFilterAll}?offset=0&limit=0`;
    return this.apiService.get(path).pipe(
        map((response) => response.data),
        tap((storeData) => {
          this.getStoreTypes.next(storeData.stores)
        }),
    );
}

  private getStoreById(storeId: string): Observable<ApiResponse> {
    const path = apiRoutes.storeFilterById(storeId);
    return this.apiService.get(path);
  }

  openStoreEditor(data: StoreEditorData): void {
    this.openStoreEditorSubject.next(data);
  }
}
