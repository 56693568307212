import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {LayoutComponent} from './core/components/layout/layout.component';
import {NotfoundComponent} from './shared/notfound/notfound.component';
import {authGuard} from './core/guards/auth.guard';
import {adminGuard} from './core/guards/admin.guard';
import {setAuthStoreResolver} from './core/route-resolvers/set-auth-store.resolver';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', component: LayoutComponent,
                canActivate: [authGuard],
                resolve: {
                    setAuthStore: setAuthStoreResolver
                },
                children: [
                    {
                        path: 'dashboard',
                        canActivate: [adminGuard],
                        loadChildren: () => import('./features/dashboard/dashboard.module').then(m => m.DashboardModule)
                    },
                    {
                        path: 'users',
                        canActivate: [adminGuard],
                        loadChildren: () => import('./features/users/users.module').then(m => m.UsersModule)
                    },

                    {
                        path: 'category',
                        canActivate: [adminGuard],
                        loadChildren: () => import('./features/category/category.module').then(m => m.CategoryModule)
                    },
                    {
                        path: 'stores',
                        loadChildren: () => import('./features/stores/stores.module').then(m => m.StoresModule)
                    },
                    {
                        path: 'user-profile-update',
                        loadChildren: () => import('./features/user-profile/user-profile.module').then(m => m.UserProfileModule)
                    }
                ]
            },
            {path: 'auth', loadChildren: () => import('./features/auth/auth.module').then(m => m.AuthModule)},
            {path: 'notfound', component: NotfoundComponent},
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
