import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { AppBaseService } from '../services/app-base.service';
import type { Store } from 'src/app/types/store/store';
import { UserTypes } from 'src/app/types/user/user-types';

export const setAuthStoreResolver: ResolveFn<Store | Boolean > = (route, state) => {
  const appBaseService = inject(AppBaseService);

  if (appBaseService.getAuthUser().userType === UserTypes.STORE_HOLDER) {
        return appBaseService.setAuthUserStore();
  } else {
    return true;
  }
};
