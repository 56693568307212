// Prod
const apiBase = 'https://api.djgroupohio.com';

// Dev
// const apiBase = 'http://localhost:4000';

export const apiRoutes = {
    category: `${apiBase}/category`,
    userRegister: `${apiBase}/user/register`,
    userLogin: `${apiBase}/user/login`,
    userRequestToken: `${apiBase}/user/reset-password/request-token`,
    userValidateToken: `${apiBase}/user/reset-password/validate-token`,
    userResetPassword: `${apiBase}/user/reset-password/add-new-password`,
    users: `${apiBase}/user`,
    usersFilter: `${apiBase}/user/filter`,
    user: (id) => `${apiBase}/user/filter/by-id?id=${id}`,
    userAddNewPassword: `${apiBase}/user/reset-password/add-new-password`,
    stores: `${apiBase}/store`,
    storeCreate: `${apiBase}/store/create`,
    fileCreate: `${apiBase}/file-manager/create`,
    fileUpdate: `${apiBase}/file-manager/update`,
    fileSingle: (id: string) => `${apiBase}/file-manager/${id}`,
    filesByStore: `${apiBase}/file-manager/filter/byStore`,
    storeUpdate: (id) => `${apiBase}/store/update/${id}`,
    storeFilterByEmail: `${apiBase}/store/filter/byEmail`,
    storeFilterById: (id) => `${apiBase}/store/filter/byId/${id}`,
    storeTypesFilterAll: `${apiBase}/store/types/filter/all`,
    storeTypesUpdate: (id) => `${apiBase}/store/types/update/${id}`,
    storeTypesCreate: `${apiBase}/store/types/create`,
    storeTypesDelete: (id) => `${apiBase}/store/types/${id}`,
    storeReport: (id) => `${apiBase}/store/report/${id}`,
    storeDashboardReport: `${apiBase}/store/report`,
    commonLogs: `${apiBase}/common-logs/filter/all`,
};
