import { CommonModule } from '@angular/common';
import {
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { catchError, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';
import { InputNumberModule } from 'primeng/inputnumber';
import {
    type FileSelectEvent,
    FileUpload,
    FileUploadModule,
} from 'primeng/fileupload';
import type { Message } from 'primeng/api';
import { DropdownModule } from 'primeng/dropdown';

import { AppBaseService } from 'src/app/core/services/app-base.service';
import { NotificationService } from '../../services/notification.service';
import { StoreEditorService } from '../../services/store-editor.service';

import type { ApiErrorResponse } from 'src/app/types/api/api-error-response';
import type {
    Store,
    StoreEditorData,
    StoreType,
} from 'src/app/types/store/store';
import { type User, UserTypes } from 'src/app/types/user/user-types';
import type { ApiResponse } from 'src/app/types/api/api-response';
import { isEmpty, isNil } from 'lodash';

@Component({
    selector: 'app-store-editor',
    templateUrl: './store-editor.component.html',
    styleUrl: './store-editor.component.scss',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SidebarModule,
        InputTextModule,
        InputNumberModule,
        ButtonModule,
        FileUploadModule,
        ToastModule,
        DropdownModule,
    ],
})
export class StoreEditorComponent implements OnInit, OnDestroy {
    @Output() isOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    isOpen: boolean;
    store: Store;
    isEdit: boolean;
    title: string;
    selectedImage?: string | ArrayBuffer;
    maxFileUploadSize = 1000000;
    storeTypes: StoreType[] = [];
    $destroy: Subject<boolean> = new Subject();
    isLoading = false;

    @ViewChild(FileUpload) fileUpload: FileUpload;

    errorMessages: Subject<Message[]> = new Subject();
    storeEditorForm = this.formBuilder.group({
        storeName: ['', Validators.required],
        storeType: ['', Validators.required],
        description: ['', Validators.required],
        contactNo: [
            '',
            [
                Validators.required,
                Validators.pattern(new RegExp('[0-9 ]{10}')),
                Validators.maxLength(10),
            ],
        ],
        address: [''],
        image: [null],
        userId: [null],
        email: [null],
    });

    constructor(
        private formBuilder: FormBuilder,
        private notificationService: NotificationService,
        private appBaseService: AppBaseService,
        private storeEditorService: StoreEditorService,
        private router: Router
    ) {}

    ngOnInit(): void {
        const authUser = this.appBaseService.getAuthUser();
        if (authUser.userType === UserTypes.STORE_HOLDER) {
            this.initAuthUserStore(authUser).subscribe();
        }

        this.openStoreEditorForUser().subscribe();
        this.storeEditorService.getAllStoreTypes().pipe(take(1)).subscribe();

        this.storeEditorService.$getStoreTypes
            .pipe(
                takeUntil(this.$destroy),
                tap((storeData) => {
                    this.storeTypes = storeData;
                })
            )
            .subscribe();
    }

    ngOnDestroy(): void {
        this.$destroy.next(true);
        this.$destroy.complete();
    }

    private initAuthUserStore(authUser: User): Observable<Store> {
        return this.appBaseService.$userStore.pipe(
            take(1),
            tap((userStore) => {
                if (isEmpty(userStore)) {
                    this.setFormInitialsForUser(authUser);
                }
                this.router.navigate([`stores/my-store`]);
            })
        );
    }

    private openStoreEditorForUser(): Observable<StoreEditorData> {
        return this.storeEditorService.$openStoreEditor.pipe(
            tap((editorData) => {
                this.isOpen = true;
                this.isEdit = editorData.isEdit;
                this.setFormInitialsForUser({
                    _id: editorData.userId,
                    email: editorData.email,
                });
                if (!isNil(editorData.store)) {
                    this.setEditStoreFormData(editorData.store);
                }
            })
        );
    }

    private setEditStoreFormData(store: Store): void {
        this.store = store;
        this.storeEditorForm.patchValue({
            userId: store.userId,
            storeType: store.storeTypeId || store.storeType,
            email: store.email,
            storeName: store.storeName,
            contactNo: store.contactNo,
            description: store.description,
            address: store.address,
        });
    }

    private setFormInitialsForUser(authUser: Partial<User>): void {
        this.storeEditorForm.patchValue({
            userId: authUser._id,
            email: authUser.email,
        });
    }

    onCloseEditor(): void {
        this.storeEditorForm.reset();
        this.selectedImage = undefined;
        this.errorMessages.next([]);
    }

    onSelect(event: FileSelectEvent): void {
        const selectedFile: File = event.files[0];
        this.storeEditorForm.patchValue({ image: selectedFile });
        this.fileUpload.clear();
        this.priviewImage(selectedFile);
    }

    private priviewImage(file: File): void {
        let reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
            this.selectedImage = event.target.result;
        };
        reader.readAsDataURL(file);
    }

    onSubmit(): void {
        if (this.storeEditorForm.valid) {
            this.isLoading = true;
            const formData = new FormData();
            const storeImage = this.storeEditorForm.controls.image.value;
            if (!isEmpty(storeImage)) {
                formData.append(
                    'image',
                    this.storeEditorForm.controls.image.value
                );
            }
            formData.append(
                'storeName',
                this.storeEditorForm.controls.storeName.value
            );
            formData.append(
                'storeType',
                this.storeEditorForm.controls.storeType.value
            );
            formData.append(
                'description',
                this.storeEditorForm.controls.description.value
            );
            formData.append(
                'contactNo',
                this.storeEditorForm.controls.contactNo.value
            );
            formData.append(
                'address',
                this.storeEditorForm.controls.address.value
            );
            formData.append('email', this.storeEditorForm.controls.email.value);
            formData.append(
                'userId',
                this.storeEditorForm.controls.userId.value
            );

            this.saveStoreData(formData)
                .pipe(
                    take(1),
                    tap(() => {
                        this.isLoading = false;
                        this.notificationService.showSuccess(
                            'Success',
                            'Store saved sucessfully'
                        );
                        this.isOpen = false;
                        this.onCloseEditor();
                    }),
                    catchError((errorResponse: ApiErrorResponse) => {
                        this.isLoading = false;
                        this.errorMessages.next([
                            {
                                severity: 'error',
                                summary: 'Error',
                                key: 'store-editor-errors',
                                detail: errorResponse.message,
                            },
                        ]);
                        throw errorResponse;
                    })
                )
                .subscribe();
        }
    }

    private saveStoreData(formData: FormData): Observable<ApiResponse> {
        if (this.isEdit) {
            return this.storeEditorService.updateUserStore(
                formData,
                this.store._id
            );
        } else {
            return this.storeEditorService.createUserStore(formData);
        }
    }
}
