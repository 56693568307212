import { Component, ElementRef } from '@angular/core';
import { filter, take, tap } from 'rxjs';
import { LayoutService } from '../../services/layout.service';
import { AppBaseService } from '../../services/app-base.service';
import { isEmpty } from 'lodash';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html'
})
export class SidebarComponent {
    userStoreId: string;

    constructor(
        public layoutService: LayoutService,
        public el: ElementRef,
        private appBaseService: AppBaseService,
    ) {
        this.appBaseService.$userStore.pipe(
            take(1),
            filter((store) => !isEmpty(store)),
            tap((store) => {
                this.userStoreId = store._id;
            })
        ).subscribe();
    }
}

