import {Injectable} from '@angular/core';
import {map, Observable, ReplaySubject, tap} from 'rxjs';
import {Store} from 'src/app/types/store/store';
import {User} from 'src/app/types/user/user-types';
import {ApiService} from './api.service';
import {apiRoutes} from '../api-routes';
import {ApiResponse} from 'src/app/types/api/api-response';
import {HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AppBaseService {

    private userStore: ReplaySubject<Store> = new ReplaySubject(1);
    $userStore: Observable<Store> = this.userStore;

    constructor(
        private apiService: ApiService,
    ) {
    }

    getAuthUser(): User {
        const authToken = sessionStorage.getItem('authToken');
        const arrayToken = authToken.split('.');
        const parsedData = JSON.parse(atob(arrayToken[1]));
        const tokenPayload: User = {...parsedData, _id: parsedData.id, userType: parsedData.type};
        return tokenPayload;
    }

    setAuthUserStore(): Observable<Store> {
        const authUserData: User = this.getAuthUser();
        const params: HttpParams = new HttpParams().set("email", authUserData.email);
        return this.apiService.get(apiRoutes.storeFilterByEmail, params).pipe(
            tap((response: ApiResponse) => {
                const {data: {stores}} = response;
                this.userStore.next(stores[0]);
            }),
            map((response: ApiResponse) => response?.data?.stores[0])
        );
    }

    setSelectedUserStore(store: Store): void {
        this.userStore.next(store);
    }
}
