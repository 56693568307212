<div class="layout-wrapper" [ngClass]="containerClass">
    <app-header></app-header>
    <div class="layout-sidebar">
        <app-sidebar></app-sidebar>
        <ul class="layout-menu logout-menu border-top-1 border-300 m-2 surface-overlay">
            <ng-container *ngFor="let item of logoutMenu; let i = index;">
                <li app-menuitem [item]="item" [index]="i" [root]="true"></li>
            </ng-container>
        </ul>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
    <div class="layout-mask"></div>
</div>
<app-store-editor></app-store-editor>
