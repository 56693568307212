import { CanActivateFn } from '@angular/router';
import { AppBaseService } from '../services/app-base.service';
import { inject } from '@angular/core';
import { UserTypes } from 'src/app/types/user/user-types';

export const adminGuard: CanActivateFn = (route, state) => {
    const appBaseService = inject(AppBaseService);
    const authUser = appBaseService.getAuthUser();
    return (
        authUser.userType === UserTypes.ADMIN ||
        authUser.userType === UserTypes.SUPER_ADMIN
    );
};
