import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import isNil from 'lodash-es/isNil';

export const authGuard: CanActivateFn = (route, state) => {
  const authToken = sessionStorage.getItem("authToken");
  const router = inject(Router);

  if (!isNil(authToken)) {
    return true;
  } else {
    router.navigate(['auth']);
    return false;
  }
};
