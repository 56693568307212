import {NgModule} from '@angular/core';
import {LocationStrategy, PathLocationStrategy} from '@angular/common';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthInterceptor} from './core/interceptors/auth.interceptor';
import {HttpErrorsInterceptor} from './core/interceptors/http-errors.interceptor';
import {ToastModule} from "primeng/toast";
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NotificationService} from "./shared/services/notification.service";
import {ConfirmationService, MessageService} from "primeng/api";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import { UserProfileUpdateComponent } from './features/user-profile/components/user-profile-update/user-profile-update.component';

@NgModule({
    declarations: [AppComponent],
    imports: [
        AppRoutingModule,
        CoreModule,
        BrowserModule,
        BrowserAnimationsModule,
        ToastModule,
        ConfirmDialogModule,
    ],
    providers: [
        NotificationService,
        MessageService,
        ConfirmationService,
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptor, multi: true},
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
